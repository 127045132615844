import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import confetti from "canvas-confetti";
import * as anchor from "@project-serum/anchor";
import {
    Commitment,
    Connection,
    PublicKey,
    Transaction,
    LAMPORTS_PER_SOL
} from "@solana/web3.js";
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { GatewayProvider } from '@civic/solana-gateway-react';
import Countdown from "react-countdown";
import { Snackbar, Paper, LinearProgress, Chip, Typography, Container, Grid, Button, Box, Tab, Link, Tooltip, Portal } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { AlertState, getAtaForMint, toDate } from './utils';
import { MintButton } from './MintButton';
import {
    awaitTransactionSignatureConfirmation,
    CANDY_MACHINE_PROGRAM,
    CandyMachineAccount,
    getCandyMachineState,
    getCollectionPDA,
    mintOneToken,
    SetupState,
} from "./candy-machine";
import "@fortawesome/fontawesome-free/js/all.js";
import "./home.css";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import FraktApes from "./MintComponents/fraktApes";
import Patch from "./images/patch.png"
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { addDoc, collection, getDocs, getFirestore, query, where } from "firebase/firestore/lite";
import FraktPandas from "./MintComponents/fraktPandas";
import Gods from "./MintComponents/gods";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ComingSoon from "./MintComponents/comingSoon";

const cluster = process.env.REACT_APP_SOLANA_NETWORK!.toString();
const decimals = process.env.REACT_APP_SPL_TOKEN_TO_MINT_DECIMALS ? +process.env.REACT_APP_SPL_TOKEN_TO_MINT_DECIMALS!.toString() : 9;
const splTokenName = process.env.REACT_APP_SPL_TOKEN_TO_MINT_NAME ? process.env.REACT_APP_SPL_TOKEN_TO_MINT_NAME.toString() : "TOKEN";

const WalletContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: right;
`;

const WalletAmount = styled.div`
  color: black;
  width: auto;
  padding: 5px 5px 5px 16px;
  min-width: 48px;
  min-height: auto;
  border-radius: 22px;
  background-color: var(--main-text-color);
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  line-height: 1.75;
  text-transform: uppercase;
  border: 0;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: flex-start;
  gap: 10px;
`;

const Wallet = styled.ul`
  flex: 0 0 auto;
  margin: 0;
  padding: 0;
`;

const ConnectButton = styled(WalletMultiButton)`
  border-radius: 18px !important;
  padding: 6px 16px;
  background-color: #4E44CE;
  margin: 0 auto;
`;

const Card = styled(Paper)`
  display: inline-block;
  background-color: var(--countdown-background-color) !important;
  margin: 5px;
  min-width: 40px;
  padding: 24px;

  h1 {
    margin: 0px;
  }
`;

const MintButtonContainer = styled.div`
  button.MuiButton-contained:not(.MuiButton-containedPrimary).Mui-disabled {
    color: #464646;
  }

  button.MuiButton-contained:not(.MuiButton-containedPrimary):hover,
  button.MuiButton-contained:not(.MuiButton-containedPrimary):focus {
    -webkit-animation: pulse 1s;
    animation: pulse 1s;
    box-shadow: 0 0 0 2em rgba(255, 255, 255, 0);
  }

  @-webkit-keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #ef8f6e;
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #ef8f6e;
    }
  }
`;


const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 4%;
  margin-left: 4%;
  text-align: center;
  justify-content: center;
`;

export interface HomeProps {
    candyMachineId?: anchor.web3.PublicKey;
    connection: anchor.web3.Connection;
    txTimeout: number;
    rpcHost: string;
    network: WalletAdapterNetwork;
    firebaseApp: FirebaseApp;
}

const Home = (props: HomeProps) => {
    const [balance, setBalance] = useState<number>();
    const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT
    const [isActive, setIsActive] = useState(false); // true when countdown completes or whitelisted
    const [solanaExplorerLink, setSolanaExplorerLink] = useState<string>("");
    const [itemsAvailable, setItemsAvailable] = useState(0);
    const [itemsRedeemed, setItemsRedeemed] = useState(0);
    const [itemsRemaining, setItemsRemaining] = useState(0);
    const [isSoldOut, setIsSoldOut] = useState(false);
    const [payWithSplToken, setPayWithSplToken] = useState(false);
    const [price, setPrice] = useState(0);
    const [priceLabel, setPriceLabel] = useState<string>("SOL");
    const [whitelistPrice, setWhitelistPrice] = useState(0);
    const [whitelistEnabled, setWhitelistEnabled] = useState(false);
    const [isBurnToken, setIsBurnToken] = useState(false);
    const [whitelistTokenBalance, setWhitelistTokenBalance] = useState(0);
    const [isEnded, setIsEnded] = useState(false);
    const [endDate, setEndDate] = useState<Date>();
    const [isPresale, setIsPresale] = useState(false);
    const [isWLOnly, setIsWLOnly] = useState(false);

    const [alertState, setAlertState] = useState<AlertState>({
        open: false,
        message: "",
        severity: undefined,
    });

    const [needTxnSplit, setNeedTxnSplit] = useState(true);
    const [setupTxn, setSetupTxn] = useState<SetupState>();

    const wallet = useWallet();
    const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();

    const rpcUrl = props.rpcHost;
    const solFeesEstimation = 0.012; // approx of account creation fees

    const anchorWallet = useMemo(() => {
        if (
            !wallet ||
            !wallet.publicKey ||
            !wallet.signAllTransactions ||
            !wallet.signTransaction
        ) {
            return;
        }

        return {
            publicKey: wallet.publicKey,
            signAllTransactions: wallet.signAllTransactions,
            signTransaction: wallet.signTransaction,
        } as anchor.Wallet;
    }, [wallet]);

    async function getFirebaseCandyMachineId(): Promise<string> {
        const db = getFirestore(props.firebaseApp);
        const WL = collection(db, 'CandyMachines');

        const q = query(WL, where("name", "==", "NeuralismTickets"));
        try {
            const querySnapshot = await getDocs(q);
            let id = ""
            querySnapshot.forEach((doc) => {
                id = doc.data().machineID;
            });
            return id;
        } catch (e) {
            return ""
        }
    }

    const refreshCandyMachineState = useCallback(
        async (commitment: Commitment = 'confirmed') => {
            if (!anchorWallet) {
                return;
            }

            const connection = new Connection(props.rpcHost, commitment);

            try {
                const firebaseCandyId = await getFirebaseCandyMachineId();

                console.log(firebaseCandyId)
                if (firebaseCandyId === "") {
                    return
                }
                const candyMachineId = new anchor.web3.PublicKey(
                    firebaseCandyId
                );
                const cndy = await getCandyMachineState(
                    anchorWallet,
                    candyMachineId,
                    connection,
                );

                setCandyMachine(cndy);
                setItemsAvailable(cndy.state.itemsAvailable);
                setItemsRemaining(cndy.state.itemsRemaining);
                setItemsRedeemed(cndy.state.itemsRedeemed);

                var divider = 1;
                if (decimals) {
                    divider = +('1' + new Array(decimals).join('0').slice() + '0');
                }

                // detect if using spl-token to mint
                if (cndy.state.tokenMint) {
                    setPayWithSplToken(true);
                    // Customize your SPL-TOKEN Label HERE
                    // TODO: get spl-token metadata name
                    setPriceLabel(splTokenName);
                    setPrice(cndy.state.price.toNumber() / divider);
                    setWhitelistPrice(cndy.state.price.toNumber() / divider);
                } else {
                    setPrice(cndy.state.price.toNumber() / LAMPORTS_PER_SOL);
                    setWhitelistPrice(cndy.state.price.toNumber() / LAMPORTS_PER_SOL);
                }


                // fetch whitelist token balance
                if (cndy.state.whitelistMintSettings) {
                    setWhitelistEnabled(true);
                    setIsBurnToken(cndy.state.whitelistMintSettings.mode.burnEveryTime);
                    setIsPresale(cndy.state.whitelistMintSettings.presale);
                    setIsWLOnly(!isPresale && cndy.state.whitelistMintSettings.discountPrice === null);

                    if (cndy.state.whitelistMintSettings.discountPrice !== null && cndy.state.whitelistMintSettings.discountPrice !== cndy.state.price) {
                        if (cndy.state.tokenMint) {
                            setWhitelistPrice(cndy.state.whitelistMintSettings.discountPrice?.toNumber() / divider);
                        } else {
                            setWhitelistPrice(cndy.state.whitelistMintSettings.discountPrice?.toNumber() / LAMPORTS_PER_SOL);
                        }
                    }

                    let balance = 0;
                    try {
                        const tokenBalance =
                            await props.connection.getTokenAccountBalance(
                                (
                                    await getAtaForMint(
                                        cndy.state.whitelistMintSettings.mint,
                                        anchorWallet.publicKey,
                                    )
                                )[0],
                            );

                        balance = tokenBalance?.value?.uiAmount || 0;
                    } catch (e) {
                        console.error(e);
                        balance = 0;
                    }
                    if (commitment !== "processed") {
                        setWhitelistTokenBalance(balance);
                    }
                    setIsActive(isPresale && !isEnded && balance > 0);

                } else {
                    setWhitelistEnabled(false);
                }

                // end the mint when date is reached
                if (cndy?.state.endSettings?.endSettingType.date) {
                    setEndDate(toDate(cndy.state.endSettings.number));
                    if (
                        cndy.state.endSettings.number.toNumber() <
                        new Date().getTime() / 1000
                    ) {
                        setIsEnded(true);
                        setIsActive(false);
                    }
                }
                // end the mint when amount is reached
                if (cndy?.state.endSettings?.endSettingType.amount) {
                    let limit = Math.min(
                        cndy.state.endSettings.number.toNumber(),
                        cndy.state.itemsAvailable,
                    );
                    setItemsAvailable(limit);
                    if (cndy.state.itemsRedeemed < limit) {
                        setItemsRemaining(limit - cndy.state.itemsRedeemed);
                    } else {
                        setItemsRemaining(0);
                        cndy.state.isSoldOut = true;
                        setIsEnded(true);
                    }
                } else {
                    setItemsRemaining(cndy.state.itemsRemaining);
                }

                if (cndy.state.isSoldOut) {
                    setIsActive(false);
                }

                const [collectionPDA] = await getCollectionPDA(candyMachineId);
                const collectionPDAAccount = await connection.getAccountInfo(
                    collectionPDA,
                );

                const txnEstimate =
                    892 +
                    (!!collectionPDAAccount && cndy.state.retainAuthority ? 182 : 0) +
                    (cndy.state.tokenMint ? 66 : 0) +
                    (cndy.state.whitelistMintSettings ? 34 : 0) +
                    (cndy.state.whitelistMintSettings?.mode?.burnEveryTime ? 34 : 0) +
                    (cndy.state.gatekeeper ? 33 : 0) +
                    (cndy.state.gatekeeper?.expireOnUse ? 66 : 0);

                setNeedTxnSplit(txnEstimate > 1230);
            } catch (e) {
                if (e instanceof Error) {
                    if (
                        e.message === `Account does not exist ${props.candyMachineId}`
                    ) {
                        setAlertState({
                            open: true,
                            message: `Couldn't fetch candy machine state from candy machine with address: ${props.candyMachineId}, using rpc: ${props.rpcHost}! You probably typed the REACT_APP_CANDY_MACHINE_ID value in wrong in your .env file, or you are using the wrong RPC!`,
                            severity: 'error',
                            hideDuration: null,
                        });
                    } else if (
                        e.message.startsWith('failed to get info about account')
                    ) {
                        setAlertState({
                            open: true,
                            message: `Couldn't fetch candy machine state with rpc: ${props.rpcHost}! This probably means you have an issue with the REACT_APP_SOLANA_RPC_HOST value in your .env file, or you are not using a custom RPC!`,
                            severity: 'error',
                            hideDuration: null,
                        });
                    }
                } else {
                    setAlertState({
                        open: true,
                        message: `${e}`,
                        severity: 'error',
                        hideDuration: null,
                    });
                }
                console.log(e);
            }

        },
        [anchorWallet, props.candyMachineId, props.rpcHost, isEnded, isPresale, props.connection],
    );

    const renderGoLiveDateCounter = ({ days, hours, minutes, seconds }: any) => {
        return (
            <div><Card elevation={1}><h1>{days}</h1>Days</Card><Card elevation={1}><h1>{hours}</h1>
                Hours</Card><Card elevation={1}><h1>{minutes}</h1>Mins</Card><Card elevation={1}>
                    <h1>{seconds}</h1>Secs</Card></div>
        );
    };

    const renderEndDateCounter = ({ days, hours, minutes }: any) => {
        let label = "";
        if (days > 0) {
            label += days + " days "
        }
        if (hours > 0) {
            label += hours + " hours "
        }
        label += (minutes + 1) + " minutes left to MINT."
        return (
            <div><h3>{label}</h3></div>
        );
    };

    function displaySuccess(mintPublicKey: any, qty: number = 1): void {
        let remaining = itemsRemaining - qty;
        setItemsRemaining(remaining);
        setIsSoldOut(remaining === 0);
        if (isBurnToken && whitelistTokenBalance && whitelistTokenBalance > 0) {
            let balance = whitelistTokenBalance - qty;
            setWhitelistTokenBalance(balance);
            setIsActive(isPresale && !isEnded && balance > 0);
        }
        setSetupTxn(undefined);
        setItemsRedeemed(itemsRedeemed + qty);
        if (!payWithSplToken && balance && balance > 0) {
            setBalance(balance - ((whitelistEnabled ? whitelistPrice : price) * qty) - solFeesEstimation);
        }
        setSolanaExplorerLink(cluster === "devnet" || cluster === "testnet"
            ? ("https://solscan.io/token/" + mintPublicKey + "?cluster=" + cluster)
            : ("https://solscan.io/token/" + mintPublicKey));
        setIsMinting(false);
        throwConfetti();
    };

    function throwConfetti(): void {
        confetti({
            particleCount: 400,
            spread: 70,
            origin: { y: 0.6 },
        });
    }

    async function addWLToAllCollections(): Promise<void> {
        // Get all collections
        const db = getFirestore(props.firebaseApp);

        const querySnapshot = await getDocs(collection(db, "CandyMachines"));
        querySnapshot.forEach(async (doc) => {
            // doc.data() is never undefined for query doc snapshots
            const whiteListName = doc.data().wlCollectionName
            const whiteListCollection = collection(db, whiteListName)
            await addDoc(whiteListCollection, {
                address: wallet.publicKey?.toString(),
                status: "notMinted"
            });
            console.log("Added whitelist for : ", wallet.publicKey?.toString());
        });

        return
    }

    // const onMint = async (
    //     beforeTransactions: Transaction[] = [],
    //     afterTransactions: Transaction[] = [],
    // ) => {
    //     await addWLToAllCollections()
    // }

    const onMint = async (
        beforeTransactions: Transaction[] = [],
        afterTransactions: Transaction[] = [],
    ) => {
        try {
            if (wallet.connected && candyMachine?.program && wallet.publicKey) {
                setIsMinting(true);
                const mint = anchor.web3.Keypair.generate();
                let mintResult = await mintOneToken(
                    candyMachine,
                    wallet.publicKey,
                    mint,
                    beforeTransactions,
                    afterTransactions,
                    // setupState,
                );

                let status: any = { err: true };
                let metadataStatus = null;
                if (mintResult) {
                    status = await awaitTransactionSignatureConfirmation(
                        mintResult.mintTxId,
                        props.txTimeout,
                        props.connection,
                        true,
                    );

                    metadataStatus =
                        await candyMachine.program.provider.connection.getAccountInfo(
                            mintResult.metadataKey,
                            'processed',
                        );
                    console.log('Metadata status: ', !!metadataStatus);
                }

                if (status && !status.err && metadataStatus) {
                    await addWLToAllCollections()
                    setAlertState({
                        open: true,
                        message: 'Congratulations! Mint succeeded!',
                        severity: 'success',
                    });
                    // update front-end amounts
                    displaySuccess(mint.publicKey);
                    refreshCandyMachineState('processed');
                } else if (status && !status.err) {
                    setAlertState({
                        open: true,
                        message:
                            'Mint likely failed! Anti-bot SOL 0.01 fee potentially charged! Check the explorer to confirm the mint failed and if so, make sure you are eligible to mint before trying again.',
                        severity: 'error',
                        hideDuration: 8000,
                    });
                    refreshCandyMachineState();
                } else {
                    setAlertState({
                        open: true,
                        message: 'Mint failed! Please try again!',
                        severity: 'error',
                    });
                    refreshCandyMachineState();
                }
            }
        } catch (error: any) {
            let message = error.msg || 'Minting failed! Please try again!';
            if (!error.msg) {
                if (!error.message) {
                    message = 'Transaction Timeout! Please try again.';
                } else if (error.message.indexOf('0x138')) {
                } else if (error.message.indexOf('0x137')) {
                    message = `SOLD OUT!`;
                } else if (error.message.indexOf('0x135')) {
                    message = `Insufficient funds to mint. Please fund your wallet.`;
                }
            } else {
                if (error.code === 311) {
                    message = `SOLD OUT!`;
                } else if (error.code === 312) {
                    message = `Minting period hasn't started yet.`;
                }
            }

            setAlertState({
                open: true,
                message,
                severity: "error",
            });
        } finally {
            setIsMinting(false);
        }
    };
    useEffect(() => {
        (async () => {
            if (anchorWallet) {
                const balance = await props.connection.getBalance(anchorWallet!.publicKey);
                setBalance(balance / LAMPORTS_PER_SOL);
            }
        })();
    }, [anchorWallet, props.connection]);

    useEffect(() => {
        refreshCandyMachineState();
    }, [
        anchorWallet,
        props.candyMachineId,
        props.connection,
        isEnded,
        isPresale,
        refreshCandyMachineState
    ]);

    const [infoState, setInfoState] = useState<boolean>(false);

    const toggleInfo = () => {
        if (infoState) {
            setInfoState(false);
        } else {
            setInfoState(true);
        }
    };

    const [value, setValue] = useState('1');
    const handleChange = (event: any, newValue: string) => {
        setValue(newValue);
    };

    const [openTooltip, setOpenToolTip] = useState(false);
    const [openTooltipHappened, setOpenToolTipHappened] = useState(false);

    const handleClose = () => {
        if (!openTooltipHappened) {
            setOpenToolTip(true);
            setOpenToolTipHappened(true)
        } else {
            setOpenToolTip(false);
        }
    };

    const handleOpen = () => {
        if (!openTooltipHappened) {
            setOpenToolTip(true);
            setOpenToolTipHappened(true)
        } else {
            setOpenToolTip(false);
        }
    };

    const countdownTime = new Date().getTime() + 2000

    useEffect(() => {
        // if (!openTooltipHappened){
        setTimeout(() => handleOpen(), 5000);
        // return () => {
        //     clearInterval(interval);
        // };
        // } 

    }, [openTooltipHappened, handleOpen
    ]);

    return (
        <main>
            <MainContainer>
                {/* <WalletContainer>
                    <Wallet>
                        {wallet ?
                            <WalletAmount><ConnectButton /></WalletAmount> :
                            <ConnectButton>Connect Wallet</ConnectButton>}
                    </Wallet>
                </WalletContainer> */}

                <Typography
                    align="center"
                    variant="h2"
                    style={{ fontWeight: 1000, fontFamily: "robo" }}
                >
                    Neuralism
                </Typography>

                <Typography
                    align="center"
                    variant="h6"
                    style={{ fontWeight: 500, fontFamily: "robo" }}
                >
                    Your gateway to NFTs built by AI
                </Typography>
                <Container>
                    <Grid container direction="row" justifyContent="center">
                        <Button
                            href={"https://discord.gg/SKPeV4k7vh"}
                            target="_blank"
                            rel="noreferrer"
                            style={{ marginRight: 10 }}
                        >
                            <i
                                className="fab fa-discord fa-3x"
                                style={{
                                    color: "#fff",
                                    opacity: 0.9
                                }}
                            ></i>
                        </Button>
                        <Tooltip arrow placement="bottom" open={openTooltip} onClose={handleClose} onOpen={handleOpen} title={<Typography variant="body1" style={{ color: "white", fontFamily: "robo" }}>{"Click to enter"}</Typography>}>
                            <Button onClick={toggleInfo} style={{ marginRight: 8 }}>
                                <i
                                    className="fa fa-home fa-4x"
                                    style={{ color: "#fff", opacity: 0.9, animation: "pulse 1s infinite" }}
                                ></i>
                            </Button>
                        </Tooltip>

                        <Button
                            href={"https://twitter.com/PatchNFT"}
                            target="_blank"
                            rel="noreferrer"
                            style={{}}
                        >
                            <i
                                className="fab fa-twitter fa-3x"
                                style={{ color: "#fff", opacity: 0.9 }}
                            ></i>
                        </Button>
                    </Grid>
                </Container>
                <br></br>

                {infoState ? (
                    <Container maxWidth="sm" style={{ marginTop: 15 }}>
                        <Paper
                            style={{ padding: 16, backgroundColor: "#151A1F", borderRadius: 6, paddingTop: 0 }}
                        >

                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} variant="fullWidth">
                                        <Tab style={{ fontFamily: "robo" }} label="AI Launchpad" value="1" />
                                        {/* <Tab label="Neuralism Ticket" value="2" /> */}
                                        <Tab label="Info" value="3" />
                                    </TabList>
                                </Box>

                                <TabPanel value="1">
                                    <Grid container spacing={2} direction="column">
                                    <Grid item>
                                            <Typography
                                                align="center"
                                                variant="h5"
                                                style={{ color: "lightgrey", fontFamily: "robo" }}
                                            >
                                                Sold Out
                                            </Typography>
                                        </Grid>
                                        
                                        

                                        <Grid item>
                                            <FraktApes connected={wallet.connected} countdownTime={new Date().getTime() - 10000} setAlertState={setAlertState} setIsMinting={setIsMinting} displaySuccess={displaySuccess} txTimeout={props.txTimeout} anchorWallet={anchorWallet} isMinting={isMinting} firebaseApp={props.firebaseApp} />
                                        </Grid>

                                        <Grid item>
                                            <Typography
                                                align="center"
                                                variant="h5"
                                                style={{ color: "lightgrey", fontFamily: "robo" }}
                                            >
                                                Future collections
                                            </Typography>
                                            </Grid>
                                        
                                        <Grid item>
                                            <FraktPandas connected={wallet.connected} countdownTime={new Date().getTime() + 100000000000} setAlertState={setAlertState} setIsMinting={setIsMinting} displaySuccess={displaySuccess} txTimeout={props.txTimeout} anchorWallet={anchorWallet} isMinting={isMinting} firebaseApp={props.firebaseApp} />
                                        </Grid>
                                        <Grid item>
                                            <Gods connected={wallet.connected} countdownTime={new Date().getTime() + 100000000000} setAlertState={setAlertState} setIsMinting={setIsMinting} displaySuccess={displaySuccess} txTimeout={props.txTimeout} anchorWallet={anchorWallet} isMinting={isMinting} firebaseApp={props.firebaseApp} />
                                        </Grid>
                                        <Grid item>
                                            <ComingSoon connected={wallet.connected} countdownTime={new Date().getTime() + 100000000000} setAlertState={setAlertState} setIsMinting={setIsMinting} displaySuccess={displaySuccess} txTimeout={props.txTimeout} anchorWallet={anchorWallet} isMinting={isMinting} firebaseApp={props.firebaseApp} />
                                        </Grid>
                                        <Grid item>
                                            <Tooltip arrow placement="bottom" title={<Typography variant="body1" style={{ color: "white", fontFamily: "robo" }}>{"More added soon"}</Typography>}>
                                                <Button>
                                                    <ExpandCircleDownIcon fontSize="large" />
                                                </Button>
                                            </Tooltip>
                                        </Grid>


                                    </Grid>


                                </TabPanel>

                                <TabPanel value="2">

                                    <Grid container direction="column" justifyContent="center">

                                        {/* <img src={apecompress} alt="loading..." style={{
                                            width: "80%",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                            // marginTop: 10
                                        }} /> */}

                                        <Typography
                                            align="center"
                                            variant="h4"
                                            style={{ color: "white", fontFamily: "robo", marginTop: 10, marginBottom: 10 }}
                                        >

                                            Neuralism Ticket
                                        </Typography>

                                        <Typography
                                            align="center"
                                            variant="body1"
                                            style={{ color: "white", fontFamily: "robo", marginTop: 10 }}
                                        >
                                            Owning a Neuralism Ticket acts as a membership card to the website and community. 
                                        </Typography>

                                        <Typography
                                            align="center"
                                            variant="body1"
                                            style={{ color: "white", fontFamily: "robo" }}
                                        >
                                            Holders will be prioritized for upcoming drops, added to future whitelists and have special discord access.
                                        </Typography>
                                        <Typography
                                            align="center"
                                            variant="body1"
                                            style={{ color: "white", fontFamily: "robo", marginTop: 10 }}
                                        >
                                            500 are available now but more will be released. Anyone can join.
                                        </Typography>
                                        
                                        

                                        <Typography
                                            align="center"
                                            variant="body1"
                                            style={{ color: "white", fontFamily: "robo", marginTop: 10 }}
                                        >

                                            Price: Free*
                                        </Typography>

                                        <Typography
                                                align="center"
                                                variant="body1"
                                                style={{ color: "lightgrey", fontFamily: "robo",  fontSize: 10, marginBottom:5 }}
                                            >

                                                *0.022 SOL mint fees
                                            </Typography>

                                        {/* Mint /////// */}
                                        {/* {!wallet.connected ? (

                                            <>
                                                {!isLive ? (<MintCountdown
                                                    date={new Date(props.launchTime)}
                                                    style={{ justifyContent: "center" }}>
                                                </MintCountdown>) : (<ConnectButton>Connect Wallet</ConnectButton>)}
                                            </>

                                        ) : (
                                            <>
                                                <Header candyMachine={candyMachineArray[CandyMachineEnums.NEUTRAL_APES]} />
                                                <MintContainer>
                                                    {candyMachineArray[CandyMachineEnums.NEUTRAL_APES]?.state.isActive &&
                                                        candyMachineArray[CandyMachineEnums.NEUTRAL_APES]?.state.gatekeeper &&
                                                        wallet.publicKey &&
                                                        wallet.signTransaction ? (
                                                        <GatewayProvider
                                                            wallet={{
                                                                publicKey:
                                                                    wallet.publicKey ||
                                                                    new PublicKey(CANDY_MACHINE_PROGRAM),
                                                                //@ts-ignore
                                                                signTransaction: wallet.signTransaction
                                                            }}
                                                            gatekeeperNetwork={
                                                                candyMachineArray[CandyMachineEnums.NEUTRAL_APES]?.state?.gatekeeper?.gatekeeperNetwork
                                                            }
                                                            clusterUrl={rpcUrl}
                                                            options={{ autoShowModal: false }}
                                                        >
                                                            <MintButton
                                                                candyMachine={candyMachineArray[CandyMachineEnums.NEUTRAL_APES]}
                                                                isMinting={isUserMinting}
                                                                onMint={onMintNeutral}
                                                            />
                                                        </GatewayProvider>
                                                    ) : (
                                                        <MintButton
                                                            candyMachine={candyMachineArray[CandyMachineEnums.NEUTRAL_APES]}
                                                            isMinting={isUserMinting}
                                                            onMint={onMintNeutral}
                                                        />
                                                    )}
                                                </MintContainer>
                                            </>
                                        )} */}
                                    </Grid>

                                    {/* <MintContainer> */}
                                    {/* <DesContainer> */}
                                    {/* <NFT elevation={3}> */}
                                    {/* <h2>My NFT</h2>
                                    <br /> */}
                                    {/* <div><Price
                                        label={isActive && whitelistEnabled && (whitelistTokenBalance > 0) ? (whitelistPrice + " " + priceLabel) : (price + " " + priceLabel)} /><Image
                                            src="cool-cats.gif"
                                            alt="NFT To Mint" /></div>
                                    <br /> */}
                                    {/* <br></br> */}

                                    
                                    <>
                                    {wallet && isActive && whitelistEnabled && (whitelistTokenBalance > 0) && isBurnToken &&
                                        <h3>You own {whitelistTokenBalance} WL
                                            mint {whitelistTokenBalance > 1 ? "tokens" : "token"}.</h3>}
                                    {wallet && isActive && whitelistEnabled && (whitelistTokenBalance > 0) && !isBurnToken &&
                                        <h3>You are whitelisted and allowed to mint.</h3>}
                                    {wallet && isActive && endDate && Date.now() < endDate.getTime() &&
                                        <Countdown
                                            date={toDate(candyMachine?.state?.endSettings?.number)}
                                            onMount={({ completed }) => completed && setIsEnded(true)}
                                            onComplete={() => {
                                                setIsEnded(true);
                                            }}
                                            renderer={renderEndDateCounter}
                                        />}
                                    {/* {wallet && isActive &&
                                        <h3>TOTAL MINTED : {itemsRedeemed} / {itemsAvailable}</h3>}
                                    {wallet && isActive && <BorderLinearProgress variant="determinate"
                                        value={100 - (itemsRemaining * 100 / itemsAvailable)} />} */}
                                 
                                    <MintButtonContainer>
                                        {!isActive && !isEnded && candyMachine?.state.goLiveDate && (!isWLOnly || whitelistTokenBalance > 0) ? (
                                            <Countdown
                                                date={toDate(candyMachine?.state.goLiveDate)}
                                                onMount={({ completed }) => completed && setIsActive(!isEnded)}
                                                onComplete={() => {
                                                    setIsActive(!isEnded);
                                                }}
                                                renderer={renderGoLiveDateCounter}
                                            />) : (
                                            !wallet ? (
                                                <ConnectButton>Connect Wallet</ConnectButton>
                                            ) : (!isWLOnly || whitelistTokenBalance > 0) ?
                                                candyMachine?.state.gatekeeper &&
                                                    wallet.publicKey &&
                                                    wallet.signTransaction ? (
                                                    <GatewayProvider
                                                        wallet={{
                                                            publicKey:
                                                                wallet.publicKey ||
                                                                new PublicKey(CANDY_MACHINE_PROGRAM),
                                                            //@ts-ignore
                                                            signTransaction: wallet.signTransaction,
                                                        }}
                                                        // // Replace with following when added
                                                        // gatekeeperNetwork={candyMachine.state.gatekeeper_network}
                                                        gatekeeperNetwork={
                                                            candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                                                        } // This is the ignite (captcha) network
                                                        /// Don't need this for mainnet
                                                        clusterUrl={rpcUrl}
                                                        cluster={cluster}
                                                        options={{ autoShowModal: false }}
                                                    >
                                                        <MintButton
                                                            candyMachine={candyMachine}
                                                            isMinting={isMinting}
                                                            isActive={isActive}
                                                            isEnded={isEnded}
                                                            isSoldOut={isSoldOut}
                                                            onMint={onMint}
                                                        />
                                                    </GatewayProvider>
                                                ) : (
                                                    <MintButton
                                                        candyMachine={candyMachine}
                                                        isMinting={isMinting}
                                                        isActive={isActive}
                                                        isEnded={isEnded}
                                                        isSoldOut={isSoldOut}
                                                        onMint={onMint}
                                                    />

                                                ) :
                                                <h1>Mint is private.</h1>
                                        )}
                                    </MintButtonContainer>
                                    <Typography
                                                align="center"
                                                variant="body1"
                                                style={{ color: "lightgrey", fontFamily: "robo", fontSize: 10,marginTop: 5 }}
                                            >
                                                Readme before minting 👇
                                            </Typography>

                                    <Typography
                                            align="center"
                                            variant="body1"
                                            style={{ color: "lightgrey", fontFamily: "robo", fontSize: 10 }}
                                        >
                                            Feel free to mint from a brand new wallet for safety. 
                                        </Typography>
                                    </>
                                </TabPanel>
                                <TabPanel value="3">

                                    <Grid container direction="column" justifyContent="center">

                                        {/* <Typography
                                            align="center"
                                            variant="body1"
                                            style={{ color: "white", fontFamily: "robo", marginTop: 0 }}
                                        >
                                            By the end of the decade it is likely we will have artifical general intelegence, surpassing our human creativily.
                                        </Typography>

                                        <Typography
                                            align="center"
                                            variant="body1"
                                            style={{ color: "white", fontFamily: "robo", marginTop: 0 }}
                                        >
                                            Before then AI can be used as a tool, one use to make beautifle and powerful artwork.
                                        </Typography> */}
<Typography
                                            align="center"
                                            variant="body2"
                                            style={{ color: "lightgrey", fontFamily: "robo", }}
                                        >
                                            *Project on hold*
                                        </Typography>

                                        <Typography
                                            align="center"
                                            variant="body1"
                                            style={{ color: "white", fontFamily: "robo", marginTop: 20, marginBottom: 10 }}
                                        >
                                            Neuralism is an AI artwork launchpad.
                                        </Typography>

                                        <Typography
                                            align="center"
                                            variant="body1"
                                            style={{ color: "white", fontFamily: "robo", marginBottom: 20  }}
                                        >
                                            Neuralism brings together the NFT communities by combining projects and giving the NFTs back to them for free (See Frakt Apes).
                                        </Typography>

                                        {/* <Typography
                                            align="center"
                                            variant="body1"
                                            style={{ color: "white", fontFamily: "robo", marginTop: 20, marginBottom: 20 }}
                                        >
                                            Royalties from the collection will be reinvested back into producing more collections, giving ticket holders ongoing utility.
                                        </Typography> */}

                                        {/* <Typography
                                            align="center"
                                            variant="body1"
                                            style={{ color: "white", fontFamily: "robo", marginTop: 20, marginBottom: 20 }}
                                        >
                                            Royalties from the collection will be reinvested back into producing more collections, giving ticket holders ongoing utility.
                                        </Typography> */}

                                        <img src={Patch} alt="loading..." style={{
                                            width: "35%",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                        }} />

                                        <Typography
                                            align="center"
                                            variant="body1"
                                            style={{ color: "white", fontFamily: "robo", marginTop: 10 }}
                                        >
                                            A Project by
                                            <Link variant="body1" underline="always" align="center" style={{ color: "white", fontFamily: "robo", margin: "auto", paddingLeft: 8 }} href="https://twitter.com/PatchNFT">Patch</Link>
                                        </Typography>

                                    </Grid>

                                </TabPanel>
                            </TabContext>

                        </Paper>
                    </Container>
                ) : <></>}
            </MainContainer>
            <Portal>
                <Snackbar
                    open={alertState.open}
                    autoHideDuration={6000}
                    onClose={() => setAlertState({ ...alertState, open: false })}
                >
                    <Alert
                        onClose={() => setAlertState({ ...alertState, open: false })}
                        severity={alertState.severity}
                    >
                        {alertState.message}
                    </Alert>
                </Snackbar>
            </Portal>
        </main>
    );
};

export default Home;
