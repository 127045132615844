import { Box, Button, Container, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, Tooltip, Typography, Link, TextField } from "@material-ui/core";
import React, { Dispatch, useEffect, useState } from "react";
import { FunctionComponent } from "react";

import CloseIcon from '@mui/icons-material/Close';
import "./ribbon.css";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import WhiteApeBanner from "../images/white-apes-banner2.png"
import { FirebaseApp} from 'firebase/app';
import { getFirestore, collection, getDocs, where, query, setDoc, doc, DocumentData } from 'firebase/firestore/lite';
import { AlertState } from "../utils";
import { CandyMachineAccount, getCandyMachineState } from "../candy-machine";
import * as anchor from "@project-serum/anchor";
import fraktApesGif from "../images/frakt-apes-final.webp"
import screenshot5 from "../images/Screenshot5.png"
import screenshot4 from "../images/Screenshot4.png"
import screenshot3 from "../images/Screenshot3.png"
import screenshot2 from "../images/Screenshot2.png"
import screenshot1 from "../images/Screenshot1.png"
import { MintCountdown } from "./MintCountdown";

interface Props {
    countdownTime: number;
    connected?: boolean;
    mintProps?: string;
    setAlertState: Dispatch<React.SetStateAction<AlertState>>,
    // alertState: AlertState;
    setIsMinting: Dispatch<React.SetStateAction<boolean>>,
    isMinting: boolean;
    displaySuccess: (mintPublicKey: any, qty?: number) => void,
    txTimeout: number;
    anchorWallet: anchor.Wallet | undefined;
    firebaseApp: FirebaseApp;
    // refreshCandyMachineState: () => void;
}
const FraktApesWLCollection = 'FraktApesWL';
const FraktApes: FunctionComponent<Props> = ({
    countdownTime,
    setAlertState,
    // alertState,
    anchorWallet,
    firebaseApp
    // refreshCandyMachineState
}) => {
    const [textInput, setTextInput] = useState('');

    const handleTextInputChange = (event: any) => {
        setTextInput(event.target.value);
    };

    const name = "Frakt Apes"
    const tooltip = "Requires: Degen Ape or FRAKT"
    const [openLive, setOpenLive] = React.useState(false);
    const [openNotLive, setOpenNotLive] = React.useState(false);
    const [tooltipDiabled, setTooltipDisabled] = React.useState(false);
    const [isLive, setIsLive] = useState(countdownTime < new Date().getTime());

    const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
    // setIsLive(countdownTime < new Date().getTime());

    const handleClickOpen = () => {
        setTooltipDisabled(true)
        setOpenLive(true);
    };
    const handleClose = () => {
        setTooltipDisabled(false)
        setOpenLive(false);
    };

    const handleClickOpenNotLive = () => {
        setTooltipDisabled(true)
        setOpenNotLive(true);
    };
    const handleCloseNotLive = () => {
        setTooltipDisabled(false)
        setOpenNotLive(false);
    };

    const wallet = useWallet();
    const connection = useConnection();

    async function getFirebaseWL(): Promise<DocumentData> {
        const db = getFirestore(firebaseApp);
        const WL = collection(db, FraktApesWLCollection);
        const q = query(WL, where("address", "==", wallet.publicKey?.toString()));
        const querySnapshot = await getDocs(q);
        let data = {}
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            if (doc.data().status !== "minted") {
                // console.log("found available doc");
                data = doc
            }
        });
        return data
    }

    async function openFirebaseLinks(): Promise<DocumentData[]> {

        const db = getFirestore(firebaseApp);

        console.log(textInput == "")
        if (textInput == "") {
            setAlertState({
                open: true,
                message: 'You have not entered a valid SOL address!',
                severity: 'error',
                hideDuration: 8000,
            });
            return []
        }

        const WL = collection(db, "FraktApesLinks");
        const q = query(WL, where("address", "==", textInput));
        const querySnapshot = await getDocs(q);
        let data = new Array<DocumentData>()
        let foundLinks = false;
        querySnapshot.forEach((doc) => {
            foundLinks = true;
            window.open(`${doc.data().link}&network=mainnet-beta`, '_blank', 'noopener,noreferrer');
        });

        if (!foundLinks) {
            setAlertState({
                open: true,
                message: 'Mint failed! You do not appear to have an unused Degen Ape or FRAKT (as of )!',
                severity: 'error',
                hideDuration: 8000,
            });
        }
        return data
    }

    useEffect(() => {
        const interval = setInterval(() => setIsLive(countdownTime < new Date().getTime()), 1000);
        return () => {
            clearInterval(interval);
        };
    }, [countdownTime
    ]);

    async function getFirebaseCandyMachineId(): Promise<string> {
        const db = getFirestore(firebaseApp);
        const WL = collection(db, 'CandyMachines');

        const q = query(WL, where("name", "==", "FraktApes"));
        try {
            const querySnapshot = await getDocs(q);
            let id = ""
            querySnapshot.forEach((doc) => {
                id = doc.data().machineID;
            });
            return id;
        } catch (e) {
            return ""
        }
    }

    const refreshCandyMachineState = async () => {
        if (!anchorWallet) {
            return;
        }
        const firebaseCandyId = await getFirebaseCandyMachineId();

        console.log(firebaseCandyId)
        if (firebaseCandyId === "") {
            return
        }
        const candyMachineId = new anchor.web3.PublicKey(
            firebaseCandyId
        );

        const candyMachine = await getCandyMachineState(
            anchorWallet!,
            candyMachineId,
            connection.connection,
        );

        setCandyMachine(candyMachine);
    }

    useEffect(() => {
        refreshCandyMachineState()
    }, [connection.connection, anchorWallet, firebaseApp]);

    return (
        <Container>
            <Tooltip title={<Typography variant="body1" style={{ color: "white", fontFamily: "robo" }}>{tooltip}</Typography>} arrow disableHoverListener={tooltipDiabled}>
                <Button fullWidth>
                    {!isLive ?
                        <>
                            <Dialog
                                onClose={handleCloseNotLive}
                                aria-labelledby="customized-dialog-title"
                                open={openNotLive}
                            >
                                <Paper style={{ padding: 16, backgroundColor: "#151A1F", borderRadius: 10, paddingTop: 0 }}>
                                    <BootstrapDialogTitle id="modal" onClose={handleCloseNotLive}>
                                        COMING SOON ...
                                    </BootstrapDialogTitle>
                                    <DialogContent dividers>
                                        <Grid container direction="column" justifyContent="center">

                                            <Typography
                                                align="center"
                                                variant="body1"
                                                style={{ color: "white", fontFamily: "robo", marginTop: 5, marginBottom: 5 }}
                                            >
                                                Required to mint:
                                            </Typography>
                                            <Typography
                                                align="center"
                                                variant="body1"
                                                style={{ color: "white", fontFamily: "robo", marginBottom: 10 }}
                                            >
                                                DEGEN APE or FRAKT
                                            </Typography>

                                        </Grid>
                                    </DialogContent>
                                </Paper>

                            </Dialog>
                            <Paper elevation={0} variant="outlined" style={{ width: "100%", backgroundColor: "#130110", borderRadius: 1 }} >
                                <Box sx={{
                                    width: "100%",
                                    height: 80,
                                    display: 'flex'
                                }}
                                    onClick={handleClickOpenNotLive} >
                                    <Grid container direction="row" style={{ margin: 5, width: "100%" }}>
                                        <Grid item xs={5}>
                                            <Typography align="center"
                                                variant="body2"
                                                style={{ color: "white", fontFamily: "robo", marginTop: 22, marginLeft: 25 }}>
                                                Available in:
                                            </Typography>
                                            {/* <Typography align="center"
                                            variant="body2"
                                            style={{ color: "white", fontFamily: "robo", marginBottom: 6, marginLeft: 25 }}>
                                            Available in:
                                        </Typography> */}
                                        </Grid>
                                        <Grid item xs={7}>
                                            <MintCountdown
                                                date={new Date(countdownTime)}
                                                style={{ justifyContent: "center", marginTop: 6 }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </>
                        :
                        <>
                            <Dialog
                                onClose={handleClose}
                                aria-labelledby="customized-dialog-title"
                                open={openLive}
                            >
                                <Paper style={{ padding: 16, backgroundColor: "#151A1F", borderRadius: 10, paddingTop: 0 }}>
                                    <BootstrapDialogTitle id="modal" onClose={handleClose}>
                                        <Typography style={{ color: "white", fontFamily: "robo" }} variant="h5" align="center">{name}</Typography>
                                    </BootstrapDialogTitle>
                                    <DialogContent dividers>
                                        <Grid container direction="column" justifyContent="center" >
                                            {/* <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                border: 'solid white',
                                                borderRadius: 10,
                                                width: '80%',
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                            }}>
                                                <Grid container direction="column" justifyContent="center">
                                                    <Typography
                                                        align="center"
                                                        variant="body1"
                                                        style={{ color: "white", fontFamily: "robo", marginTop: 10 }}
                                                    >
                                                        1 Free Claim for:
                                                    </Typography>
                                                    <Typography
                                                        align="center"
                                                        variant="body1"
                                                        style={{ color: "white", fontFamily: "robo", marginBottom: 10 }}
                                                    >
                                                        DEGEN APE or FRAKT holders or WL
                                                    </Typography>
                                                    
                                                </Grid>
                                            </Box> */}


                                            <img src={fraktApesGif} alt="loading ..." style={{
                                                width: "60%",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                marginTop: 10
                                            }} />

                                            <Typography
                                                align="center"
                                                variant="body1"
                                                style={{ color: "white", fontFamily: "robo", marginTop: 10 }}
                                            >
                                                Artificial Neural Networks have been used to combine<Link variant="body1" underline="always" align="center" style={{ color: "white", fontFamily: "robo", margin: "auto", paddingLeft: 8, paddingRight: 8 }} href="https://www.degenape.academy/">Degen Apes</Link>
                                                and<Link variant="body1" underline="always" align="center" style={{ color: "white", fontFamily: "robo", margin: "auto", paddingLeft: 8, paddingRight: 8 }} href="https://magiceden.io/marketplace/frakt">FRAKT</Link>

                                                artwork, two OG Solana NFT projects.
                                            </Typography>

                                            <Typography
                                                align="center"
                                                variant="body1"
                                                style={{ color: "white", fontFamily: "robo", marginTop: 10 }}
                                            >

↓ Post launch reviews ↓
                                            </Typography>

                                            <img src={screenshot3} alt="loading ..." style={{
                                                width: "80%",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                marginTop: 10
                                            }} />
                                            <img src={screenshot2} alt="loading ..." style={{
                                                width: "80%",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                marginTop: 10
                                            }} />
                                            <img src={screenshot1} alt="loading ..." style={{
                                                width: "80%",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                marginTop: 10
                                            }} />
                                             <img src={screenshot4} alt="loading ..." style={{
                                                width: "80%",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                marginTop: 10
                                            }} />
                                             <img src={screenshot5} alt="loading ..." style={{
                                                width: "80%",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                marginTop: 10
                                            }} />
                                            
                                            {/* <Typography
                                                align="center"
                                                variant="body1"
                                                style={{ color: "white", fontFamily: "robo", }}
                                            >

                                                5,300 going to FRAKT and Degen Ape holders 
                                            
                                            </Typography>
                                            <Typography
                                                align="center"
                                                variant="body1"
                                                style={{ color: "white", fontFamily: "robo", marginBottom: 20 }}
                                            >

                                                200 going to the Team
                                            </Typography> */}
{/* 
                                            <Typography
                                                align="center"
                                                variant="body1"
                                                style={{ color: "white", fontFamily: "robo" }}
                                            >

                                                Price: Free*
                                            </Typography>

                                            <Typography
                                                align="center"
                                                variant="body1"
                                                style={{ color: "lightgrey", fontFamily: "robo", fontSize: 10 }}
                                            >
                                                * ~0.019 SOL to cover mint fees + project costs
                                            </Typography>

                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                border: 'solid white',
                                                borderRadius: 10,
                                                width: '80%',
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                marginTop:15,
                                                marginBottom:10
                                            }}>
                                                <Grid container direction="column" justifyContent="center" >

                                                    <Typography
                                                        align="center"
                                                        variant="body1"
                                                        style={{ color: "white", fontFamily: "robo", fontSize: 12, marginTop: 5 }}
                                                    >
                                                        How to mint 👇
                                                    </Typography>

                                                    <Typography
                                                        align="center"
                                                        variant="body1"
                                                        style={{ color: "lightgrey", fontFamily: "robo", fontSize: 10, marginTop: 2 }}
                                                    >
                                                        1. If you fit the mint requirements enter your SOL address and click "Mint via Gumdrop"
                                                    </Typography>
                                                    <Typography
                                                        align="center"
                                                        variant="body1"
                                                        style={{ color: "lightgrey", fontFamily: "robo", fontSize: 10, marginTop: 2 }}
                                                    >
                                                        2. This will open a third party site by Metaplex (Official Solana NFT platform)
                                                    </Typography>
                                                    <Typography
                                                        align="center"
                                                        variant="body1"
                                                        style={{ color: "lightgrey", fontFamily: "robo", fontSize: 10, marginTop: 2, marginBottom:5 }}
                                                    >
                                                        3. Connect wallet to gumdrop and click "Claim Gumdrop". You will be able to claim 1 NFT
                                                    </Typography>
                                                </Grid>
                                            </Box>
                                            <TextField
                                                style={{ margin: 5 }} id="filled-basic" label="Enter SOL address" variant="filled"

                                                value={textInput}
                                                onChange={handleTextInputChange}
                                            />
                                            <Grid container direction="row" justify="center" alignItems="center" style={{ marginTop: 10 }}>
                                                <Button onClick={openFirebaseLinks} color="secondary" variant="contained">
                                                    Mint Via Gumdrop
                                                </Button>
                                            </Grid> */}

                                        </Grid>
                                    </DialogContent>
                                </Paper>
                            </Dialog>
                            <Paper elevation={0} style={{
                                backgroundImage: `url(${WhiteApeBanner})`,
                                backgroundSize: 'cover',
                                borderRadius: 10,
                                width: "100%"

                            }} className="parent">
                                <Box sx={{
                                    width: "100%",
                                    height: 80,
                                    display: 'flex'
                                }}
                                    onClick={handleClickOpen}
                                >
                                    <Grid container direction="row" style={{ margin: 5 }}>
                                        <Grid item xs={5}>
                                            <Typography align="center"
                                                variant="body1"
                                                style={{ color: "white", fontFamily: "robo", marginTop: 20, marginLeft: 25 }}>
                                                {name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7}>

                                            {/* <Typography className="ribbon">
                                                FREE*
                                            </Typography> */}
                                        </Grid>
                                    </Grid>

                                </Box>
                            </Paper>
                        </>
                    }
                </Button >
            </Tooltip>
        </Container>
    )


}

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}


const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default FraktApes;