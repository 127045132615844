// Write a react component  

import { Box, Button, Container, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, Tooltip, Typography } from "@material-ui/core";
import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { FunctionComponent } from "react";

import CloseIcon from '@mui/icons-material/Close';
import "./ribbon.css";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import PandaBanner from "../images/pandas-banner.png"
import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, Firestore, where, query, setDoc, doc, DocumentData } from 'firebase/firestore/lite';
import { initializeAppCheck, ReCaptchaEnterpriseProvider, ReCaptchaV3Provider } from "firebase/app-check";
import { AlertState } from "../utils";
import { awaitTransactionSignatureConfirmation, CandyMachineAccount, createAccountsForMint, getCandyMachineState, mintOneToken, SetupState } from "../candy-machine";
import { Commitment, LAMPORTS_PER_SOL, Transaction } from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";
import { MintButton } from "../MintButton";

import apecompress from "../images/frakt-pandas-compress.webp"
import { MintCountdown } from "./MintCountdown";
import confetti from "canvas-confetti";


interface Props {
    countdownTime: number;
    connected?: boolean;
    mintProps?: string;
    setAlertState: Dispatch<React.SetStateAction<AlertState>>,
    // alertState: AlertState;
    setIsMinting: Dispatch<React.SetStateAction<boolean>>,
    isMinting: boolean;
    displaySuccess: (mintPublicKey: any, qty?: number) => void,
    txTimeout: number;
    anchorWallet: anchor.Wallet | undefined;
    firebaseApp: FirebaseApp;
    // refreshCandyMachineState: () => void;
}
const FraktApesWLCollection = 'FraktPandasWL';
const FraktPandas: FunctionComponent<Props> = ({
    countdownTime,
    mintProps,
    setAlertState,
    // alertState,
    setIsMinting,
    isMinting,
    displaySuccess,
    txTimeout,
    anchorWallet,
    firebaseApp
    // refreshCandyMachineState
}) => {
    const name = "Frakt Trash Pandas"
    const tooltip = "Requires: TBC"
    const [openLive, setOpenLive] = React.useState(false);
    const [openNotLive, setOpenNotLive] = React.useState(false);
    const [tooltipDiabled, setTooltipDisabled] = React.useState(false);
    const [isLive, setIsLive] = useState(countdownTime < new Date().getTime());
    const [firebaseDocId, setFirebaseDocId] = useState("");

    const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
    // setIsLive(countdownTime < new Date().getTime());

    const handleClickOpen = () => {
        setTooltipDisabled(true)
        setOpenLive(true);
    };
    const handleClose = () => {
        setTooltipDisabled(false)
        setOpenLive(false);
    };

    const handleClickOpenNotLive = () => {
        setTooltipDisabled(true)
        setOpenNotLive(true);
    };
    const handleCloseNotLive = () => {
        setTooltipDisabled(false)
        setOpenNotLive(false);
    };


    const wallet = useWallet();
    const connection = useConnection();


    async function getFirebaseWL(): Promise<DocumentData> {
        const db = getFirestore(firebaseApp);
        const WL = collection(db, FraktApesWLCollection);
        const q = query(WL, where("address", "==", wallet.publicKey?.toString()));
        const querySnapshot = await getDocs(q);
        let data = {}
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            if (doc.data().status !== "minted") {
                // console.log("found available doc");
                data = doc
            }
        });
        return data
    }

    async function updateFirebaseMintingStatus(wl: DocumentData, status: string): Promise<void> {
        const db = getFirestore(firebaseApp);
        // console.log("updateFirebaseMintingStatus", wl.data(), status);
        const cityRef = doc(db, FraktApesWLCollection, wl.id);
        await setDoc(cityRef, { status: status }, { merge: true });
    }

    async function updateFirebaseTxIdMetadata(wl: DocumentData, id: string, metadataKey: string): Promise<void> {
        const db = getFirestore(firebaseApp);
        // console.log("updateFirebaseTxId", wl.id, id);
        const cityRef = doc(db, FraktApesWLCollection, wl.id);
        await setDoc(cityRef, { txId: id, metadataKey: metadataKey }, { merge: true });
    }




    useEffect(() => {
        const interval = setInterval(() => setIsLive(countdownTime < new Date().getTime()), 1000);
        return () => {
            clearInterval(interval);
        };
    }, [countdownTime
    ]);

    async function getFirebaseCandyMachineId(): Promise<string> {
        const db = getFirestore(firebaseApp);
        const WL = collection(db, 'CandyMachines');

        const q = query(WL, where("name", "==", "FraktPandas"));
        try {
            const querySnapshot = await getDocs(q);
            let id = ""
            querySnapshot.forEach((doc) => {
                id = doc.data().machineID;
            });
            return id;
        } catch (e) {
            return ""
        }
    }

    const refreshCandyMachineState = async () => {
        if (!anchorWallet) {
            return;
        }
        const firebaseCandyId = await getFirebaseCandyMachineId();

        console.log(firebaseCandyId)
        if (firebaseCandyId === "") {
            return
        }
        const candyMachineId = new anchor.web3.PublicKey(
            firebaseCandyId
        );

        const candyMachine = await getCandyMachineState(
            anchorWallet!,
            candyMachineId,
            connection.connection,
        );

        setCandyMachine(candyMachine);
    }

    useEffect(() => {
        refreshCandyMachineState()
    }, [connection.connection, anchorWallet, firebaseApp]);


    // const refreshCandyMachineState = useCallback(
    //     async (commitment: Commitment = 'confirmed') => {
    //         if (!anchorWallet) {
    //             return;
    //         }

    //         const connection = new Connection(props.rpcHost, commitment);

    //         if (props.candyMachineId) {
    //             try {
    //                 const cndy = await getCandyMachineState(
    //                     anchorWallet,
    //                     props.candyMachineId,
    //                     connection,
    //                 );

    //                 setCandyMachine(cndy);
    //                 setItemsAvailable(cndy.state.itemsAvailable);
    //                 setItemsRemaining(cndy.state.itemsRemaining);
    //                 setItemsRedeemed(cndy.state.itemsRedeemed);

    //                 var divider = 1;
    //                 if (decimals) {
    //                     divider = +('1' + new Array(decimals).join('0').slice() + '0');
    //                 }

    //                 // detect if using spl-token to mint
    //                 if (cndy.state.tokenMint) {
    //                     setPayWithSplToken(true);
    //                     // Customize your SPL-TOKEN Label HERE
    //                     // TODO: get spl-token metadata name
    //                     setPriceLabel(splTokenName);
    //                     setPrice(cndy.state.price.toNumber() / divider);
    //                     setWhitelistPrice(cndy.state.price.toNumber() / divider);
    //                 } else {
    //                     setPrice(cndy.state.price.toNumber() / LAMPORTS_PER_SOL);
    //                     setWhitelistPrice(cndy.state.price.toNumber() / LAMPORTS_PER_SOL);
    //                 }


    //                 // fetch whitelist token balance
    //                 if (cndy.state.whitelistMintSettings) {
    //                     setWhitelistEnabled(true);
    //                     setIsBurnToken(cndy.state.whitelistMintSettings.mode.burnEveryTime);
    //                     setIsPresale(cndy.state.whitelistMintSettings.presale);
    //                     setIsWLOnly(!isPresale && cndy.state.whitelistMintSettings.discountPrice === null);

    //                     if (cndy.state.whitelistMintSettings.discountPrice !== null && cndy.state.whitelistMintSettings.discountPrice !== cndy.state.price) {
    //                         if (cndy.state.tokenMint) {
    //                             setWhitelistPrice(cndy.state.whitelistMintSettings.discountPrice?.toNumber() / divider);
    //                         } else {
    //                             setWhitelistPrice(cndy.state.whitelistMintSettings.discountPrice?.toNumber() / LAMPORTS_PER_SOL);
    //                         }
    //                     }

    //                     let balance = 0;
    //                     try {
    //                         const tokenBalance =
    //                             await props.connection.getTokenAccountBalance(
    //                                 (
    //                                     await getAtaForMint(
    //                                         cndy.state.whitelistMintSettings.mint,
    //                                         anchorWallet.publicKey,
    //                                     )
    //                                 )[0],
    //                             );

    //                         balance = tokenBalance?.value?.uiAmount || 0;
    //                     } catch (e) {
    //                         console.error(e);
    //                         balance = 0;
    //                     }
    //                     if (commitment !== "processed") {
    //                         setWhitelistTokenBalance(balance);
    //                     }
    //                     setIsActive(isPresale && !isEnded && balance > 0);

    //                 } else {
    //                     setWhitelistEnabled(false);
    //                 }

    //                 // end the mint when date is reached
    //                 if (cndy?.state.endSettings?.endSettingType.date) {
    //                     setEndDate(toDate(cndy.state.endSettings.number));
    //                     if (
    //                         cndy.state.endSettings.number.toNumber() <
    //                         new Date().getTime() / 1000
    //                     ) {
    //                         setIsEnded(true);
    //                         setIsActive(false);
    //                     }
    //                 }
    //                 // end the mint when amount is reached
    //                 if (cndy?.state.endSettings?.endSettingType.amount) {
    //                     let limit = Math.min(
    //                         cndy.state.endSettings.number.toNumber(),
    //                         cndy.state.itemsAvailable,
    //                     );
    //                     setItemsAvailable(limit);
    //                     if (cndy.state.itemsRedeemed < limit) {
    //                         setItemsRemaining(limit - cndy.state.itemsRedeemed);
    //                     } else {
    //                         setItemsRemaining(0);
    //                         cndy.state.isSoldOut = true;
    //                         setIsEnded(true);
    //                     }
    //                 } else {
    //                     setItemsRemaining(cndy.state.itemsRemaining);
    //                 }

    //                 if (cndy.state.isSoldOut) {
    //                     setIsActive(false);
    //                 }

    //                 const [collectionPDA] = await getCollectionPDA(props.candyMachineId);
    //                 const collectionPDAAccount = await connection.getAccountInfo(
    //                     collectionPDA,
    //                 );

    //                 const txnEstimate =
    //                     892 +
    //                     (!!collectionPDAAccount && cndy.state.retainAuthority ? 182 : 0) +
    //                     (cndy.state.tokenMint ? 66 : 0) +
    //                     (cndy.state.whitelistMintSettings ? 34 : 0) +
    //                     (cndy.state.whitelistMintSettings?.mode?.burnEveryTime ? 34 : 0) +
    //                     (cndy.state.gatekeeper ? 33 : 0) +
    //                     (cndy.state.gatekeeper?.expireOnUse ? 66 : 0);

    //                 setNeedTxnSplit(txnEstimate > 1230);
    //             } catch (e) {
    //                 if (e instanceof Error) {
    //                     if (
    //                         e.message === `Account does not exist ${props.candyMachineId}`
    //                     ) {
    //                         setAlertState({
    //                             open: true,
    //                             message: `Couldn't fetch candy machine state from candy machine with address: ${props.candyMachineId}, using rpc: ${props.rpcHost}! You probably typed the REACT_APP_CANDY_MACHINE_ID value in wrong in your .env file, or you are using the wrong RPC!`,
    //                             severity: 'error',
    //                             hideDuration: null,
    //                         });
    //                     } else if (
    //                         e.message.startsWith('failed to get info about account')
    //                     ) {
    //                         setAlertState({
    //                             open: true,
    //                             message: `Couldn't fetch candy machine state with rpc: ${props.rpcHost}! This probably means you have an issue with the REACT_APP_SOLANA_RPC_HOST value in your .env file, or you are not using a custom RPC!`,
    //                             severity: 'error',
    //                             hideDuration: null,
    //                         });
    //                     }
    //                 } else {
    //                     setAlertState({
    //                         open: true,
    //                         message: `${e}`,
    //                         severity: 'error',
    //                         hideDuration: null,
    //                     });
    //                 }
    //                 console.log(e);
    //             }
    //         } else {
    //             setAlertState({
    //                 open: true,
    //                 message: `Your REACT_APP_CANDY_MACHINE_ID value in the .env file doesn't look right! Make sure you enter it in as plain base-58 address!`,
    //                 severity: 'error',
    //                 hideDuration: null,
    //             });
    //         }
    //     },
    //     [anchorWallet, props.candyMachineId, props.rpcHost, isEnded, isPresale, props.connection],
    // );

    // const onMint = async (
    //     beforeTransactions: Transaction[] = [],
    //     afterTransactions: Transaction[] = [],
    // ) => {
    //     console.log(getFirebaseConfig())
    //     try {
    //         const id = await getFirebaseCandyMachineId()
    //         console.log(id)
    //     } catch (e) {
    //         console.log(e)
    //     }

    // }

    function throwConfetti(): void {
        confetti({
            particleCount: 400,
            spread: 70,
            origin: { y: 0.6 },
            zIndex: 100000
        });
    }

    const onMint = async (
        beforeTransactions: Transaction[] = [],
        afterTransactions: Transaction[] = [],
    ) => {
        const firebaseWL = await getFirebaseWL()
        try {
            if (!anchorWallet) {
                return;
            }

            if (wallet.connected && candyMachine?.program && wallet.publicKey) {
                
                
                if (!firebaseWL.id) {
                    setAlertState({
                        open: true,
                        message: 'Mint failed! You do not have a unused Degen Ape, FRAKT or Neuralism Ticket!',
                        severity: 'error',
                        hideDuration: 8000,
                    });
                    return
                }

                 
                const balance = await connection.connection.getBalance(anchorWallet!.publicKey);

                if ((balance / LAMPORTS_PER_SOL) < 0.022) {
                    setAlertState({
                        open: true,
                        message: 'Insufficient funds to cover gas and fees!',
                        severity: 'error',
                        hideDuration: 4000,
                    });
                    return
                }


                let status: any = { err: true };
                let metadataStatus = null;
                let mintResult = null;
                setIsMinting(true);
                if (firebaseWL.data().status === "minting" && firebaseWL.data().txId && firebaseWL.data().metadataKey){
                    // Get 
                        status = await awaitTransactionSignatureConfirmation(
                            firebaseWL.txId,
                            txTimeout,
                            connection.connection,
                            true,
                        );
                        metadataStatus =
                        await candyMachine.program.provider.connection.getAccountInfo(
                            firebaseWL.metadataKey,
                            'processed',
                        );

                }
                else if (firebaseWL.data().status === "minting") {
                    setAlertState({
                        open: true,
                        message: 'Something went wrong with your mint, please contact me in the discord!',
                        severity: 'error',
                        hideDuration: 8000,
                    });
                    return
                }
                 else {
                    await updateFirebaseMintingStatus(firebaseWL, "minting")
                    
                    const mint = anchor.web3.Keypair.generate();
                    mintResult = await mintOneToken(
                        candyMachine,
                        wallet.publicKey,
                        mint,
                        beforeTransactions,
                        afterTransactions,
                        // setupState,
                    );
                }
                
                if (mintResult) {
                    // Update firebase with txId
                    await updateFirebaseTxIdMetadata(firebaseWL, mintResult.mintTxId, mintResult.metadataKey.toString())

                    status = await awaitTransactionSignatureConfirmation(
                        mintResult.mintTxId,
                        txTimeout,
                        connection.connection,
                        true,
                    );

                    metadataStatus =
                        await candyMachine.program.provider.connection.getAccountInfo(
                            mintResult.metadataKey,
                            'processed',
                        );
                    console.log('Metadata status: ', !!metadataStatus);
                }

                if (status && !status.err && metadataStatus) {
                    await updateFirebaseMintingStatus(firebaseWL, "minted")
                    setAlertState({
                        open: true,
                        message: 'Congratulations! Mint succeeded!',
                        severity: 'success',
                    });

                    throwConfetti();
                    // displaySuccess(mint.publicKey);
                } else if (status && !status.err) {
                    // Check what happened and update firebase
                    // await updateFirebaseMintingStatus(firebaseWL, "notMinted")

                    setAlertState({
                        open: true,
                        message:
                            'Mint likely failed! Anti-bot SOL 0.01 fee potentially charged! Check the explorer to confirm the mint failed and if so, make sure you are eligible to mint before trying again.',
                        severity: 'error',
                        hideDuration: 8000,
                    });
                    // refreshCandyMachineState();
                } else {
                    await updateFirebaseMintingStatus(firebaseWL, "notMinted")
                    setAlertState({
                        open: true,
                        message: 'Mint failed! Please try again!',
                        severity: 'error',
                    });
                    // refreshCandyMachineState();
                }
            }
        } catch (error: any) {
            let message = error.msg || 'Minting failed! Please try again!';
            if (!error.msg) {
                if (!error.message) {
                    message = 'Transaction Timeout! Please try again.';
                } else if (error.message.indexOf('0x138')) {
                } else if (error.message.indexOf('0x137')) {
                    await updateFirebaseMintingStatus(firebaseWL, "notMinted")
                    message = `SOLD OUT!`;
                } else if (error.message.indexOf('0x135')) {
                    await updateFirebaseMintingStatus(firebaseWL, "notMinted")
                    message = `Insufficient funds to mint. Please fund your wallet.`;
                }
            } else {
                if (error.code === 311) {
                    await updateFirebaseMintingStatus(firebaseWL, "notMinted")
                    message = `SOLD OUT!`;
                } else if (error.code === 312) {
                    await updateFirebaseMintingStatus(firebaseWL, "notMinted")
                    message = `Minting period hasn't started yet.`;
                }
            }

            setAlertState({
                open: true,
                message,
                severity: "error",
            });
        } finally {
            setIsMinting(false);
        }
    };


    return (
        <Container>
            <Tooltip title={<Typography variant="body1" style={{ color: "white", fontFamily: "robo" }}>{tooltip}</Typography>} arrow disableHoverListener={tooltipDiabled}>
                <Button fullWidth>
                    {!isLive ?
                        <>
                            <Dialog
                                onClose={handleCloseNotLive}
                                aria-labelledby="customized-dialog-title"
                                open={openNotLive}
                            >
                                <Paper style={{ padding: 16, backgroundColor: "#151A1F", borderRadius: 10, paddingTop: 0 }}>
                                <BootstrapDialogTitle id="modal" onClose={handleCloseNotLive}>
                                <Typography style={{ color: "white", fontFamily: "robo" }} variant="h5" align="center">Coming Soon</Typography>
                                </BootstrapDialogTitle>
                                <DialogContent dividers>
                                    <Grid container direction="column" justifyContent="center">

                                    <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            border: 'solid white',
                                            borderRadius: 10,
                                            width: '80%',
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                        }}>
                                            <Grid container direction="column" justifyContent="center">
                                                <Typography
                                                    align="center"
                                                    variant="body1"
                                                    style={{ color: "white", fontFamily: "robo", marginTop: 10 }}
                                                >
                                                    Required to mint:
                                                </Typography>
                                                <Typography
                                                    align="center"
                                                    variant="body1"
                                                    style={{ color: "white", fontFamily: "robo", marginBottom: 10 }}
                                                >
                                                    TBC
                                                </Typography>
                                            </Grid>
                                        </Box>


                                        <img src={apecompress} alt="loading ..." style={{
                                            width: "70%",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                            marginTop: 10
                                        }} />

                                    </Grid>
                                </DialogContent>
                            </Paper>

                            </Dialog>
                            {/* <Paper elevation={0} variant="outlined" style={{ width: "100%", backgroundColor: "#130110", borderRadius: 1 }} >
                                <Box sx={{
                                    width: "100%",
                                    height: 80,
                                    display: 'flex'
                                }}
                                    onClick={handleClickOpenNotLive} >
                                    <Grid container direction="row" style={{ margin: 5, width: "100%" }}>
                                        <Grid item xs={5}>
                                            <Typography align="center"
                                                variant="body2"
                                                style={{ color: "white", fontFamily: "robo", marginTop: 22, marginLeft: 25 }}>
                                                Available in:
                                            </Typography>
                                            {/* <Typography align="center"
                                            variant="body2"
                                            style={{ color: "white", fontFamily: "robo", marginBottom: 6, marginLeft: 25 }}>
                                            Available in:
                                        </Typography> *
                                        </Grid>
                                        <Grid item xs={7}>
                                            <MintCountdown
                                                date={new Date(countdownTime)}
                                                style={{ justifyContent: "center", marginTop: 6 }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper> */}
                            <Paper elevation={0} variant="outlined" style={{width: "100%", backgroundColor: "#130110", borderRadius: 1 }} >
                            <Box sx={{
                                width: "100%",
                                height: 80,
                                display: 'flex'
                            }}
                                onClick={handleClickOpenNotLive} >
                                <Grid container direction="row" style={{ margin: 5, width: "100%" }}>
                                    {/* <Grid item xs={5}> */}
                                        <Typography align="center"
                                            variant="body1"
                                            style={{ color: "white", fontFamily: "robo", marginTop: 22, marginLeft: 40}}>
                                            Click for Preview
                                        </Typography>
                                        {/* <Typography align="center"
                                            variant="body2"
                                            style={{ color: "white", fontFamily: "robo", marginBottom: 6, marginLeft: 25 }}>
                                            Available in:
                                        </Typography> */}
                                    {/* </Grid> */}
                                    {/* <Grid item xs={7}> */}
                                        {/* <MintCountdown
                                            date={new Date(countdownTime)}
                                            style={{ justifyContent: "center", marginTop: 6 }}
                                        /> */}
                                    {/* </Grid> */}
                                </Grid>
                            </Box>
                        </Paper>
                        </>
                        :
                        <>
                        
                            <Dialog
                                onClose={handleClose}
                                aria-labelledby="customized-dialog-title"
                                open={openLive}
                            >
                                <Paper style={{ padding: 16, backgroundColor: "#151A1F", borderRadius: 10, paddingTop: 0 }}>
                                    <BootstrapDialogTitle id="modal" onClose={handleClose}>
                                        <Typography style={{ color: "white", fontFamily: "robo" }} variant="h5" align="center">{name}</Typography>
                                    </BootstrapDialogTitle>
                                    <DialogContent dividers>
                                        <Grid container direction="column" justifyContent="center" >
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                border: 'solid white',
                                                borderRadius: 10,
                                                width: '80%',
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                            }}>
                                                <Grid container direction="column" justifyContent="center">
                                                    <Typography
                                                        align="center"
                                                        variant="body1"
                                                        style={{ color: "white", fontFamily: "robo", marginTop: 10 }}
                                                    >
                                                        Required to mint:
                                                    </Typography>
                                                    <Typography
                                                        align="center"
                                                        variant="body1"
                                                        style={{ color: "white", fontFamily: "robo", marginBottom: 10 }}
                                                    >
                                                        TBC
                                                    </Typography>
                                                </Grid>
                                            </Box>


                                            <img src={apecompress} alt="loading ..." style={{
                                                width: "70%",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                marginTop: 10
                                            }} />

                                            <Typography
                                                align="center"
                                                variant="body1"
                                                style={{ color: "white", fontFamily: "robo", marginTop: 10 }}
                                            >
                                                Artificial Neural Networks have been used to combine Trash Pandas
                                                and FRAKT artwork.
                                            </Typography>

                                            <Typography
                                                align="center"
                                                variant="body1"
                                                style={{ color: "white", fontFamily: "robo", marginTop: 10, marginBottom: 10 }}
                                            >
                                                1000 Frakt pandas have been created for Neuralism Ticket holders. A taste of what is to come.
                                            </Typography>

                                            <Typography
                                                align="center"
                                                variant="body1"
                                                style={{ color: "white", fontFamily: "robo" }}
                                            >

                                                Price: Free*
                                            </Typography>

                                            <Typography
                                                align="center"
                                                variant="body1"
                                                style={{ color: "lightgrey", fontFamily: "robo", marginBottom: 10, fontSize: 10 }}
                                            >

                                                (0.022 SOL fee to cover costs)
                                            </Typography>

                                            {/* <Button onClick={onMint}>
                                            MINT
                                        </Button> */}
                                            <MintButton

                                                candyMachine={candyMachine}
                                                isMinting={isMinting}
                                                isActive={true}
                                                isEnded={false}
                                                isSoldOut={false}
                                                onMint={onMint}
                                            />


                                        </Grid>
                                    </DialogContent>
                                </Paper>
                            </Dialog>
                            <Paper elevation={0} style={{
                                backgroundImage: `url(${PandaBanner})`,
                                backgroundSize: 'cover',
                                borderRadius: 10,
                                width: "100%"

                            }} className="parent">
                                <Box sx={{
                                    width: "100%",
                                    height: 80,
                                    display: 'flex'
                                }}
                                    onClick={handleClickOpen}
                                >
                                    <Grid container direction="row" style={{ margin: 5 }}>
                                        <Grid item xs={5}>
                                            <Typography align="center"
                                                variant="body1"
                                                style={{ color: "white", fontFamily: "robo", marginTop: 12, marginLeft: 25 }}>
                                                {name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7}>

                                            <Typography className="ribbon">
                                                FREE*
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                </Box>
                            </Paper>
                        </>
                    }

                </Button >
            </Tooltip>
        </Container>
    )


}

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}


const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default FraktPandas;